import { getInternationalRegion } from "../../../utils/helpers";
import thumbnailProduct from "../../assets/images/menu-special-1.png";
import checkoutDefaultProduct from "../../assets/images/checkout-default-product.png";
import checkoutDefaultProduct2 from "../../assets/images/checkout-default-product-2.png";
import checkoutDefaultProduct3 from "../../assets/images/checkout-default-product-3.png";
const isInternationalRegion = getInternationalRegion();

// LOCAL
const productDetailDefaultLocal = {
  product: {
    productDetail: {
      id: "892120b8-3b50-455e-8afa-8100735642cf",
      productCategoryId: "f702c438-aab5-4bbb-9917-77bf35c35324",
      isHasPromotion: true,
      isPromotionProductCategory: false,
      isDiscountPercent: true,
      discountValue: 10,
      discountPrice: 10000,
      isTopping: false,
      isCombo: false,
      name: "Phở tái đặc biệt",
      thumbnail: "",
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and 
      scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
      remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
      and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
      rate: 5,
      totalReview: 10,
      productPrices: [
        {
          id: "30770019-01ee-4562-8907-30b29d60bcbc",
          isApplyPromotion: true,
          priceName: "Bình thường",
          priceValue: 70000,
          originalPrice: 80000,
          createdTime: "",
        },
        {
          id: "3f27a974-2f96-4713-a7d3-8c98698df4fd",
          isApplyPromotion: true,
          priceName: "Tô lớn",
          priceValue: 60000,
          originalPrice: 70000,
          createdTime: "",
        },
        {
          id: "c299e344-f7b3-4de3-9377-f41597e1e49f",
          isApplyPromotion: true,
          priceName: "Tô nhỏ",
          priceValue: 50000,
          originalPrice: 60000,
          createdTime: "",
        },
      ],
      productOptions: [
        {
          id: "e76cc1d9-276b-48c6-9cb6-f4bbf1107463", // is optionId,
          name: "Nước béo",
          optionLevels: [
            {
              id: "c867caca-72db-4316-b5bc-c5ee8e21046a",
              name: "Normal",
              isSetDefault: true,
              optionId: "e76cc1d9-276b-48c6-9cb6-f4bbf1107463",
              optionName: "",
            },
            {
              id: "ace41339-4e59-4fe6-8727-dd214bfc3952",
              name: "Ít",
              isSetDefault: false,
              optionId: "e76cc1d9-276b-48c6-9cb6-f4bbf1107463",
              optionName: "",
            },
            {
              id: "196fd0ec-2ebf-48f4-9923-c1c757574b13",
              name: "Nhiều",
              isSetDefault: false,
              optionId: "e76cc1d9-276b-48c6-9cb6-f4bbf1107463",
              optionName: "",
            },
          ],
        },
        {
          id: "59fb4466-87d7-4225-8586-b98483f16e65",
          name: "Hành lá",
          optionLevels: [
            {
              id: "20ba6b0b-e86b-48cb-a6eb-441de45e25b5",
              name: "Normal",
              isSetDefault: true,
              optionId: "59fb4466-87d7-4225-8586-b98483f16e65",
              optionName: "",
            },
            {
              id: "3263c993-7244-426d-98c1-9cad6957c156",
              name: "Ít",
              isSetDefault: false,
              optionId: "59fb4466-87d7-4225-8586-b98483f16e65",
              optionName: "",
            },
            {
              id: "1540b46d-4836-4947-93cb-a5c5f2cf20ea",
              name: "Nhiều",
              isSetDefault: false,
              optionId: "59fb4466-87d7-4225-8586-b98483f16e65",
              optionName: "",
            },
          ],
        },
        {
          id: "cd6e3fc5-53eb-452d-ae37-6e442ff194d3",
          name: "Hành tây",
          optionLevels: [
            {
              id: "bc500d25-f563-42bd-9f2a-d5a39a0317cc",
              name: "Normal",
              isSetDefault: true,
              optionId: "cd6e3fc5-53eb-452d-ae37-6e442ff194d3",
              optionName: "",
            },
            {
              id: "f67ca89c-60ca-4f63-b2fd-59a81ac87a07",
              name: "Ít",
              isSetDefault: false,
              optionId: "cd6e3fc5-53eb-452d-ae37-6e442ff194d3",
              optionName: "",
            },
            {
              id: "3fc97341-e599-4ce2-ad71-c61d669716a6",
              name: "Nhiều",
              isSetDefault: false,
              optionId: "cd6e3fc5-53eb-452d-ae37-6e442ff194d3",
              optionName: "",
            },
          ],
        },
      ],
      quantity: 0,
      originalPrice: 50000,
      priceAfterDiscount: 40000,
    },
    productToppings: [
      {
        toppingId: "4fe80370-5bec-4b4e-bfe0-5338de7cb040",
        name: "Trứng",
        priceValue: 5000,
        quantity: 0,
      },
      {
        toppingId: "0846913c-ed62-4d6c-8e96-7e47271c092e",
        name: "Tái",
        priceValue: 15000,
        quantity: 0,
      },
      {
        toppingId: "3d34d8e9-7155-40d7-8ec2-8a0090b54f18",
        name: "Nạm",
        priceValue: 15000,
        quantity: 0,
      },
      {
        toppingId: "3f8b9a50-f38c-4128-8ff9-7afbdc3c7e7b",
        name: "Gân",
        priceValue: 15000,
        quantity: 0,
      },
    ],
  },
  similarProducts: [
    {
      name: "Phở tái đặc biệt",
      thumbnail: thumbnailProduct,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 50000,
      discount: "10%",
      rate: 5,
      id: "57165905-cdd3-49a6-bfd2-30f9d690c197",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 50000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
      defaultProductPriceIndex: 0,
      discountPrice: 5000,
      discountValue: 10,
      isDiscountPercent: true,
      isFlashSale: false,
      isHasPromotion: true,
      isPromotionProductCategory: true,
    },
    {
      name: "Cam tươi cà phê",
      thumbnail: checkoutDefaultProduct,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 40000,
      rate: 5,
      id: "1bea071d-5330-447c-a48d-fc977bde1cd1",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 40000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
    },
    {
      name: "Cơm cá hồi",
      thumbnail: checkoutDefaultProduct2,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 40000,
      rate: 5,
      id: "5c462b62-ec78-45a6-b3a2-bea935ae7034",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 40000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
    },
    {
      name: "Mì xào thập cẩm",
      thumbnail: checkoutDefaultProduct3,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 40000,
      rate: 5,
      id: "cc2e92bf-9e26-4f05-a189-056b94aab9bc",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 40000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
    },
  ],
};

const relatedProductListDefaultLocal = [
  {
    name: "Phở tái đặc biệt",
    thumbnail: thumbnailProduct,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "10000",
    originalPrice: "50000",
    discount: "10%",
    rate: 5,
  },
  {
    name: "Cam tươi cà phê",
    thumbnail: checkoutDefaultProduct,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "",
    originalPrice: "40000",
    rate: 5,
  },
  {
    name: "Cơm cá hồi",
    thumbnail: checkoutDefaultProduct2,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "",
    originalPrice: "40000",
    rate: 5,
  },
  {
    name: "Mì xào thập cẩm",
    thumbnail: checkoutDefaultProduct3,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "",
    originalPrice: "40000",
    rate: 5,
  },
];

const productImagesDefaultLocal = [
  {
    name: "Phở tái đặc biệt",
    imageUrl: thumbnailProduct,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
  {
    name: "Cam tươi cà phê",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
  {
    name: "Cơm cá hồi",
    imageUrl: checkoutDefaultProduct2,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
  {
    name: "Mì xào thập cẩm",
    imageUrl: checkoutDefaultProduct3,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
];

// INTERNATIONAL
const productDetailDefaultInternational = {
  product: {
    productDetail: {
      id: "892120b8-3b50-455e-8afa-8100735642cf",
      productCategoryId: "f702c438-aab5-4bbb-9917-77bf35c35324",
      isHasPromotion: true,
      isPromotionProductCategory: false,
      isDiscountPercent: true,
      discountValue: 10,
      discountPrice: 10000,
      isTopping: false,
      isCombo: false,
      name: "Special Pho",
      thumbnail: "",
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and 
      scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
      remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
      and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
      rate: 5,
      totalReview: 10,
      productPrices: [
        {
          id: "30770019-01ee-4562-8907-30b29d60bcbc",
          isApplyPromotion: true,
          priceName: "Medium size",
          priceValue: 70000,
          originalPrice: 80000,
          createdTime: "",
        },
        {
          id: "3f27a974-2f96-4713-a7d3-8c98698df4fd",
          isApplyPromotion: true,
          priceName: "Big size",
          priceValue: 60000,
          originalPrice: 70000,
          createdTime: "",
        },
        {
          id: "c299e344-f7b3-4de3-9377-f41597e1e49f",
          isApplyPromotion: true,
          priceName: "Small size",
          priceValue: 50000,
          originalPrice: 60000,
          createdTime: "",
        },
      ],
      productOptions: [
        {
          id: "e76cc1d9-276b-48c6-9cb6-f4bbf1107463", // is optionId,
          name: "Lorem Ipsum",
          optionLevels: [
            {
              id: "c867caca-72db-4316-b5bc-c5ee8e21046a",
              name: "Normal",
              isSetDefault: true,
              optionId: "e76cc1d9-276b-48c6-9cb6-f4bbf1107463",
              optionName: "",
            },
            {
              id: "ace41339-4e59-4fe6-8727-dd214bfc3952",
              name: "Little",
              isSetDefault: false,
              optionId: "e76cc1d9-276b-48c6-9cb6-f4bbf1107463",
              optionName: "",
            },
          ],
        },
      ],
      quantity: 0,
      originalPrice: 50000,
      priceAfterDiscount: 40000,
    },
    productToppings: [
      {
        toppingId: "4fe80370-5bec-4b4e-bfe0-5338de7cb040",
        name: "Eggs",
        priceValue: 5000,
        quantity: 0,
      },
      {
        toppingId: "0846913c-ed62-4d6c-8e96-7e47271c092e",
        name: "Rare",
        priceValue: 15000,
        quantity: 0,
      },
    ],
  },
  similarProducts: [
    {
      name: "Special Pho",
      thumbnail: thumbnailProduct,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 50000,
      discount: "10%",
      rate: 5,
      id: "57165905-cdd3-49a6-bfd2-30f9d690c197",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 50000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
      defaultProductPriceIndex: 0,
      discountPrice: 5000,
      discountValue: 10,
      isDiscountPercent: true,
      isFlashSale: false,
      isHasPromotion: true,
      isPromotionProductCategory: true,
    },
    {
      name: "Seafood fried rice",
      thumbnail: checkoutDefaultProduct,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 40000,
      rate: 5,
      id: "1bea071d-5330-447c-a48d-fc977bde1cd1",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 40000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
    },
    {
      name: "Salmon rice",
      thumbnail: checkoutDefaultProduct2,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 40000,
      rate: 5,
      id: "5c462b62-ec78-45a6-b3a2-bea935ae7034",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 40000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
    },
    {
      name: "Mixed noodle",
      thumbnail: checkoutDefaultProduct3,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      sellingPrice: 40000,
      originalPrice: 40000,
      rate: 5,
      id: "cc2e92bf-9e26-4f05-a189-056b94aab9bc",
      navigateTo: "",
      productPrices: [
        {
          id: "ef7fa560-cbeb-494a-85ff-a830429fe9bb",
          isDeleted: false,
          priceValue: 40000,
          productId: "e1f5a587-8982-49f9-96be-b48c122ce407",
        },
      ],
    },
  ],
};

const relatedProductListDefaultInternational = [
  {
    name: "Special Pho",
    thumbnail: thumbnailProduct,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "10000",
    originalPrice: "50000",
    discount: "10%",
    rate: 5,
  },
  {
    name: "Seafood fried rice",
    thumbnail: checkoutDefaultProduct,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "",
    originalPrice: "40000",
    rate: 5,
  },
  {
    name: "Salmon rice",
    thumbnail: checkoutDefaultProduct2,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "",
    originalPrice: "40000",
    rate: 5,
  },
  {
    name: "Mixed noodle",
    thumbnail: checkoutDefaultProduct3,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    discountPrice: "",
    originalPrice: "40000",
    rate: 5,
  },
];

const productImagesDefaultInternational = [
  {
    name: "Special Pho",
    imageUrl: thumbnailProduct,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
  {
    name: "Seafood fried rice",
    imageUrl: checkoutDefaultProduct,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
  {
    name: "Salmon rice",
    imageUrl: checkoutDefaultProduct2,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
  {
    name: "Mixed noodle",
    imageUrl: checkoutDefaultProduct3,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
    url: "javascript:;",
  },
];

export const productDetailDefault = isInternationalRegion
  ? productDetailDefaultInternational
  : productDetailDefaultLocal;

export const relatedProductListDefault = isInternationalRegion
  ? relatedProductListDefaultInternational
  : relatedProductListDefaultLocal;

export const productImagesDefault = isInternationalRegion
  ? productImagesDefaultInternational
  : productImagesDefaultLocal;
