import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { EnumAddToCartType, EnumFlashSaleResponseCode } from "../../../../constants/enums";
import productDataService from "../../../../data-services/product-data.service";
import { store } from "../../../../modules";
import { checkOutOfStockProductList } from "../../../../services/material/check-out-of-stock.service";
import productComboAddToCartServices from "../../../../services/product-combo-add-to-cart.services";
import shoppingCartService from "../../../../services/shopping-cart/shopping-cart.service";
import { getLabelPromotion } from "../../../../utils/helpers";
import arrowLeftUrl from "../../../assets/images/back-arrow-left.png";
import arrowRightUrl from "../../../assets/images/back-arrow-right.png";
import thumbnailProduct from "../../../assets/images/menu-special-1.png";
import checkoutDefaultProduct from "../../../assets/images/checkout-default-product.png";
import checkoutDefaultProduct2 from "../../../assets/images/checkout-default-product-2.png";
import checkoutDefaultProduct3 from "../../../assets/images/checkout-default-product-3.png";
import NotificationDialog from "../../../components/notification-dialog/notification-dialog.component";
import ProductItem from "../../../components/product-item";
import { comboTypeEnum } from "../../../constants/store-web-page.constants";
import "./checkout.related.products.scss";
export default function CheckOutRelatedProducts(props) {
  const { addProductToCart, configuration, colorGroups, clickToFocusCustomize, isDefault } = props;
  const colorGroup = colorGroups?.find((c) => c.id === configuration?.colorGroupId);
  const title = configuration?.title ?? "Related Products";
  const backgroundImage = configuration?.backgroundImage;
  const backgroundColor = configuration?.backgroundColor;
  const backgroundType = configuration?.backgroundType;
  const headerStyle =
    backgroundType === 1
      ? {
          background: backgroundColor,
          display: !configuration?.visible && "none",
        }
      : {
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: !configuration?.visible && "none",
        };
  const [products, setProducts] = useState(null);
  const branchId = useSelector((state) => state.session?.deliveryAddress?.branchAddress?.id);
  const isMockup = Boolean(clickToFocusCustomize) || Boolean(isDefault);
  const [isShowNotifyFlashSaleDialog, setIsShowNotifyFlashSaleDialog] = useState(false);
  const [flashSaleProduct, setFlashSaleProduct] = useState(null);
  const [isHideArrowLeft, setIsHideArrowLeft] = useState(true);
  const [isHideArrowRight, setIsHideArrowRight] = useState(false);
  const [numberOfProductShow, setNumberOfProductShow] = useState(0);
  const swiperRef = useRef(null);

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const translatedData = {
    okay: t("storeWebPage.generalUse.okay"),
    notification: t("storeWebPage.generalUse.notification"),
    flashSaleEndNotification: t("storeWebPage.flashSale.flashSaleEndNotification"),
  };

  const relatedProductMockup = [
    {
      id: "1",
      name: "Phở tái đặc biệt",
      thumbnail: thumbnailProduct,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      productPrices: [
        {
          priceValue: 50000,
        },
      ],
    },
    {
      id: "2",
      name: "Cam tươi cà phê",
      thumbnail: checkoutDefaultProduct,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      productPrices: [
        {
          priceValue: 40000,
        },
      ],
    },
    {
      id: "3",
      name: "Cơm cá hồi",
      thumbnail: checkoutDefaultProduct2,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      productPrices: [
        {
          priceValue: 40000,
        },
      ],
    },
    {
      id: "4",
      name: "Mì xào thập cẩm",
      thumbnail: checkoutDefaultProduct3,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie.",
      productPrices: [
        {
          priceValue: 40000,
        },
      ],
    },
  ];
  const isMaxWidth640 = useMediaQuery({ maxWidth: 640 });

  const [productPricesOOS, setProductPricesOOS] = useState([]);

  async function fetchOutOfStockData(productPrices) {
    const outOfStockData = await checkOutOfStockProductList(branchId, true, productPrices);
    setProductPricesOOS(outOfStockData);
  }

  useEffect(() => {
    if (isMockup) {
      setProducts(relatedProductMockup);
      setNumberOfProductShow(relatedProductMockup.length >= 5 ? 5 : relatedProductMockup.length);
    } else {
      if (!configuration?.categoryId) return;
      loadProducts();
    }
  }, [configuration?.categoryId, branchId]);

  useEffect(() => {
    if (products) {
      let productPricesData = [];
      products?.forEach((item, index) => {
        const products =
          item?.comboTypeId == comboTypeEnum.comboProductPrice.id ? item?.comboProductPrices : item?.comboPricings;
        if (products) {
          if (item.comboTypeId == 0 && products) {
            products[0]?.comboPricingProducts?.forEach((product) => {
              productPricesData.push({
                productPriceId: product?.productPriceId,
                comboId: item?.id,
                index: index,
              });
            });
          } else {
            products?.forEach((product) => {
              productPricesData.push({
                productPriceId: product?.productPriceId,
                comboId: item?.id,
                index: index,
              });
            });
          }
        } else {
          productPricesData.push({
            productPriceId: item?.productPrices[0]?.id,
            index: index,
          });
        }
      });
      fetchOutOfStockData(productPricesData);
    }
  }, [products]);

  const loadProducts = async () => {
    if (!configuration?.categoryId) {
      setProducts([]);
      setNumberOfProductShow(0);
      return;
    }
    const responseData = await productDataService.getProductsStoreTheme(
      configuration?.categoryId,
      isMockup ? "" : branchId,
    );
    if (responseData) {
      setProducts(responseData.data.products);
      setNumberOfProductShow(responseData.data.products.length >= 5 ? 5 : responseData.data.products.length);
    }
  };

  const quickAddToCart = async (data) => {
    let requestData = {
      id: data?.id,
      productPriceId: data?.productPrices[0]?.id,
      isFlashSale: data?.isFlashSale,
      flashSaleId: data?.flashSaleId,
    };
    productComboAddToCartServices.quickAddToCart(
      requestData,
      EnumAddToCartType.Product,
      branchId,
      () => calculateShoppingCart(),
      (allAreApplicable, responseCode, product) => checkFlashSaleApplicable(allAreApplicable, responseCode, product),
    );
  };

  const checkFlashSaleApplicable = (allAreApplicable, responseCode, product) => {
    if (allAreApplicable) {
      updateCartToRedux(product);
    } else {
      if (responseCode === EnumFlashSaleResponseCode.Inactive) {
        setIsShowNotifyFlashSaleDialog(true);
        setFlashSaleProduct(product);
      } else {
        updateCartToRedux(product);
      }
    }
  };

  const handleConfirmNotify = () => {
    if (flashSaleProduct) {
      updateCartToRedux();
      setIsShowNotifyFlashSaleDialog(false);
      window.location.reload();
    }
  };

  const updateCartToRedux = (product) => {
    const currentProduct = product ?? flashSaleProduct;
    productComboAddToCartServices.updateCartInLocalAndRedux(
      productComboAddToCartServices.mappingToProductLocal(currentProduct),
      false,
    );
    calculateShoppingCart();
  };

  const calculateShoppingCart = () => {
    const reduxState = store.getState();
    const session = reduxState?.session;
    const cartItems = session?.cartItems ?? [];
    const orderInfo = session?.orderInfo;
    shoppingCartService?.setStoreCart(cartItems, true, orderInfo?.shippingFee ?? 0);
  };

  const handleLeftArrow = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
      setIsHideArrowRight(false);
      if (swiperRef.current?.swiper?.activeIndex === 0) {
        setIsHideArrowLeft(true);
      } else {
        setIsHideArrowLeft(false);
      }
    }
  };

  const handleRightArrow = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
      setIsHideArrowLeft(false);
      if (swiperRef.current?.swiper?.activeIndex === products?.length - numberOfProductShow) {
        setIsHideArrowRight(true);
      } else {
        setIsHideArrowRight(false);
      }
    }
  };

  const renderRelatedProducts = () => {
    let containerRelatedProducts = <></>;
    let containRelatedProducts = products?.map((p, index) => {
      const findOOS = productPricesOOS?.find(
        (i) => i.productPriceId && i.productPriceId == p.productPrices[0]?.id && i.outOfStock == true,
      );
      const isOutOfStock = findOOS != undefined;
      const originalPrice = p?.productPrices?.[p?.defaultProductPriceIndex ?? 0]?.originalPrice ?? 0;
      const sellingPrice = p?.productPrices?.[p?.defaultProductPriceIndex ?? 0]?.priceValue ?? 0;
      const promotionTitle =
        p?.isHasPromotion || p?.isFlashSale
          ? getLabelPromotion(
              p?.isFlashSale,
              p?.isDiscountPercent,
              p?.discountValue,
              p?.isHasPromotion,
              originalPrice,
              sellingPrice,
            )
          : null;
      const product = {
        ...p,
        originalPrice: originalPrice,
        sellingPrice: sellingPrice,
        promotionTitle: promotionTitle,
        defaultProductPriceIndex: 0,
      };
      if (isMaxWidth640) {
        return (
          <SwiperSlide className="swiper-slide-related-product">
            <ProductItem
              key={index}
              product={product}
              colorGroup={colorGroup}
              addProductToCart={() => quickAddToCart(product)}
              useIconAddtoCart={true}
              isCombo={false}
              className={"checkout-related-product-item-theme2"}
              isOutOfStock={isOutOfStock}
            />
          </SwiperSlide>
        );
      }
      return (
        <SwiperSlide key={index}>
          <ProductItem
            key={index}
            product={product}
            colorGroup={colorGroup}
            addProductToCart={() => quickAddToCart(product)}
            useIconAddtoCart={true}
            isCombo={false}
            isOutOfStock={isOutOfStock}
          />
        </SwiperSlide>
      );
    });

    if (isMaxWidth640) {
      containerRelatedProducts = (
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={12}
          pagination={{
            clickable: false,
          }}
          modules={[Pagination]}
          className="swiper-related-product"
        >
          {containRelatedProducts}
        </Swiper>
      );
    } else {
      containerRelatedProducts = (
        <>
          <Swiper
            navigation={true}
            slidesPerView={numberOfProductShow}
            spaceBetween={25}
            modules={[Navigation]}
            ref={swiperRef}
          >
            {containRelatedProducts}
          </Swiper>
          <img
            alt=""
            hidden={isHideArrowLeft || (products?.length <= numberOfProductShow && true)}
            className="button-left-arrow"
            src={arrowLeftUrl}
            onClick={handleLeftArrow}
          />
          <img
            alt=""
            hidden={isHideArrowRight || (products?.length <= numberOfProductShow && true)}
            className="button-right-arrow"
            src={arrowRightUrl}
            onClick={handleRightArrow}
          />
        </>
      );
    }
    return containerRelatedProducts;
  };

  return (
    <>
      <div
        id="themeRelatedProductsCheckout"
        onClick={() => {
          if (clickToFocusCustomize) clickToFocusCustomize("customizeRelatedProductCheckout");
        }}
      >
        <div className="check_out_theme2_related_products" style={headerStyle}>
          <div className="header_title" style={{ color: colorGroup?.titleColor }}>
            {title}
          </div>
          <div className="product_list">
            <div className="product_row">{renderRelatedProducts()}</div>
          </div>
        </div>
      </div>

      {/* Product flash sale notify */}
      <NotificationDialog
        open={isShowNotifyFlashSaleDialog}
        title={translatedData.notification}
        onConfirm={handleConfirmNotify}
        confirmLoading={false}
        className="checkout-theme1-notify-dialog"
        content={translatedData.flashSaleEndNotification}
        footer={[<Button onClick={handleConfirmNotify}>{translatedData.okay}</Button>]}
        closable={true}
      />
    </>
  );
}
